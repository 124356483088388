.top-gain-lose-main{
    .top-main-part{
        .btn-part{
            display: flex;
            gap: 11px;
            margin-bottom: 16px;
            .active{
                background: #2596BE;
                box-shadow: none;
                &:hover {
                    background: #2596BE;
                }
            }
            .deactive{
                background: rgba(37, 150, 190, 0.12);
                color: #2596BE;
                box-shadow: none;
                &:hover {
                    background: rgba(37, 150, 190, 0.12);
                }
            }
        }
        .gainer-header{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            /* identical to box height */
            color: #384955;
        }
        .accordian{
            border: none;
            .accor-summary{
                border-radius: 4px;
            }
            .accor-detail{
                padding: 16px 0px;
            }
        }
    }   
}
.sticky-table{
    box-sizing: none;
    table {
        th {
        background-color: #f1f1f1;
        border: none;
        }
        th:first-child{
            position: sticky;
            left: 0;
            background: #f1f1f1;
            z-index: 999;
        }
        td {
            border: none;
        }
        td:first-child {
            background-color: #f1f1f1;
            position: sticky;
            left: 0;
            z-index: 1;
        }
        .exchange-title {
            span {
                background: #f1f1f1;
                border-radius: 5px;
                width: 100%;
                padding: 10px;
                margin: 5px;
            }
        }
        .sub-terms {
            background: #3404641F;
            width: 50%;
            border-radius: 4px;
            text-align: center;
            color: #340464;
            font-weight: 500;
            padding: 10px;
        }
    }
}