.Btn-part {
	width: 100%;
	color: #340464 !important;
	border: 1px solid #340464 !important;
}
.home-main-section {
	margin-top: 10px;
	.section-header {
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		color: #384955;
	}
	.investor-tab {
		padding: 0px;
		padding-top: 15px;
		.investor-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: rgba(0, 112, 192, 0.1);
			border-radius: 4px;
			padding: 15px;
			margin-bottom: 30px;
			.label-part {
				display: flex;
				flex-direction: column;
				.inv-label {
					font-weight: 400;
					font-size: 12px;
					align-items: center;
					color: #384955;
				}
				.label-inner {
					display: flex;
					align-items: center;
					gap: 6px;
					span {
						font-weight: 400;
						font-size: 12px;
						color: #2596be;
					}
				}
			}
		}
		.inventory-table {
			width: 100%;
		}
	}
	.sticky-table {
		table {
			th {
			background-color: #f1f1f1;
			border: none;
			}
			th:first-child{
				position: sticky;
				left: 0;
				background: #f1f1f1;
				z-index: 999;
			}
			td {
				border: none;
			}
			td:first-child {
				background-color: #f1f1f1;
				position: sticky;
				left: 0;
				z-index: 1;
			}
		}
	}
	.collapsible-table{
		box-shadow: none;
		td {
			border: none;
		}
		td:first-child {
			background-color: #f1f1f1;
			position: sticky;
			left: 0;
			z-index: 9999;
		}
		thead th {
			background-color: #f1f1f1;
			border: none;
			position: sticky;
			left: 0;
			// width: 50%;
		}
		thead th:first-child{
			z-index: 999;
		}
	}
	.multiheader-table{
		td, th {
			border: none;
		}
		thead th {
			background-color: #f1f1f1;
			border: none;
			position: sticky;
			left: 0;
			// width: 50%;
		}
		thead th:first-child{
			z-index: 999;
		}
		tbody td:first-child{
			background-color: #f1f1f1;
			border: none;
			position: sticky;
			left: 0;
		}
		.multi-header{
			display: flex;
			width: 108.5rem;
			.header-first {
				width: 100%;
				background-color:#f1f1f1 ;
				display: flex;
				justify-content: center;
					thead {
						align-items: center;
						text-align: center;
						justify-content: center;
						display: flex;
						position: sticky;
						left: 0;
						padding: 10px; 
					}
			}
		}
	}
	.collapsed-box {
		background-color: #fff;
		table thead tr th:first-child,
		table tbody tr th{
			background-color: #f1f1f1;
			position: sticky;
			left: 0;
			z-index: 999;
			border: none;
		}
	}
}
@media (max-width:767px) {
	.collapsible-table, .sticky-table{
		table th, td{
			font-size: 12px !important;
			padding: 12px;
		}
	}
}
.plus-icon {
	display: flex;
	align-items: center;
	p {
		font-size: 14px;
	}
	.collepsible-icon{
		height: 15px;
		width: 15px;
	}
}
.action-part{
	svg{
	  height: 16px;
	  width: 16px;
	  margin-right: 2px;
	}
  }