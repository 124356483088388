.rtl{
    text-align: right;
}
.select-part{
    display: flex;
    flex-direction: column;  
    .s-market{
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #818689;
    }
    .langchange{
        font-weight: 500;
        font-size: 16px;
        margin: 20px 10px;
        color: #818689;
    }
    .sel-part{
        margin: "0 10px";
        #demo-simple-select-filled{
            padding-top: 13px;
            padding-bottom: 0px;
            .value-part{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-right: 4px;
                gap: 6px;
                transform: translate(2px, -6px);
                .inner-part{
                    font-family: 'Roboto', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23px;
                    color: #384955;
                }
            }
        }       
        .menuItem{
            border-radius: 4px;
            background-color: #fff;
        }
        .menuItem::before{
            border-bottom: none;
        }
        .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after{
            border-bottom: none;
        }
    }
}
.sidebar-icon {
    justify-content: center;
}
// .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
//     min-width: 240px !important;
//     left: 0px !important;
// }