.MainHeader {
  background-color: #fff !important;
  box-shadow: none !important;
  .toolBar {
    padding: 16px !important;
    display: flex;
    justify-content: space-between;
  }
  .header-profile {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
}
