.loading {
  height: 0;
  width: 0;
  padding: 6px;
  border: 6px solid #fff;
  border-right-color: #340464;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  // position: absolute;
  left: 50%;
  top: 10%;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
