.search-part{
    display: flex;
    gap: 20px;
    margin: 30px 0px;
    .syncButton{
        background: #73A520;
        min-width: 50px;
        &:hover {
            background: #73A520;
        }
    }
}