.price-main {
  .MuiToggleButtonGroup-grouped {
    background: #f9f9f9 !important;
    border-radius: 4px !important;
    border: none;
  }
  .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    color: #fff !important;
    background-color: #2596be !important;
    border-radius: 4px;
  }
  .PrivateTabIndicator-colorPrimary-2 {
    background-color: #2596be;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #384955;
  }
  .individual-tabs {
    margin-bottom: 30px;
  }
  .price-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .price-start {
      h4 {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #384955;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #384955;
      }
    }
    .switch-price {
      .yearly {
        color: #38495599;
      }
      .monthly {
        color: #384955;
      }
      .MuiFormControlLabel-root {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .main-package {
    border: 1px solid #dcdee1;
    border-radius: 6px;
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 20px;
    .outer-grid {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      overflow: scroll;
    }
    .price-packages {
      text-align: left;
      .package {
        align-items: center;
        color: #384955;
        h3 {
          display: flex;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
        }
        p {
          display: flex;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
        }
      }
      .package-value {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        h3 {
          display: flex;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
        }
        p {
          display: grid;
          font-weight: 400;
          font-size: 12px;
          color: #38495599;
          line-height: 14px;
          margin-left: 5px;
        }
        pre {
          text-decoration-line: line-through;
          color: #38495599;
          font-size: 14px;
        }
      }
      .subscribe {
        button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 34px;
          background: #340464;
          border-radius: 5px;
          color: #fff;
          border: 1px solid #340464;
          font-size: 14px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          color: #384955;
          line-height: 14px;
          text-align: center;
        }
      }
      .key-benifit {
        margin-top: 40px;
        h4 {
          color: #384955;
          font-weight: 500;
          font-size: 16px;
        }
        ul {
          position: relative;
          left: -10px;
          @media (max-width: 320px) {
            left: -23px;
          }
          li {
            font-weight: 400;
            font-size: 12px;
            color: #384955;
          }
        }
      }
    }
  }
}
