.main-add-user,
.admin-portal {
  .PrivateTabIndicator-colorPrimary-2 {
    background-color: transparent;
  }
  .tab-view.Mui-selected {
    color: white;
  }
  .tab-view {
    color: #dcdee1;
  }
  .section-header {
    font-weight: 700;
    font-size: 22px;
    color: #384955;
  }
  .search-part {
    display: flex;
    gap: 20px;
    margin: 30px 0px;
  }
  .add-new-admin {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #340464;
    border-radius: 40px;
    p {
      color: #340464;
    }
  }
  .MuiTabs-fixed {
    overflow: scroll !important;
  }
  .subscription-tab {
    .PrivateTabIndicator-colorPrimary-2 {
      background-color: #2596be !important;
    }
    .tab-view.Mui-selected {
      color: #384955 !important;
    }
    .userSub-btn {
      width: 100%;
    }
    .userSubTabsbtn {
      .css-d9c359-MuiButtonBase-root-MuiToggleButton-root {
        border: none;
        color: #2596be;
        background: #2596be1f;
      }
      .css-d9c359-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
        color: #fff;
        background-color: #2596be;
      }
      .toggle-group {
        display: flex;
        justify-content: space-between;
        overflow: scroll;
      }
      .toggle-btn {
        border-radius: 4px;
      }
    }
  }
}

// add user
.error-msg {
  color: #e14444;
  font-size: 14px;
  margin-top: 2px;
}
.main-add-user {
  .input-field {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    .error-msg {
      color: #e14444;
      font-size: 14px;
      margin-top: 2px;
    }
    label {
      color: #384955;
    }
  }
  .Mui-checked {
    color: #2596be !important;
  }
}
.add-service-main,
.main-add-user,
.add-market-main {
  .cancel-btn {
    width: 100%;
    border: 1px solid #dcdee1;
    color: #384955;
  }
  .MuiButton-root:hover, .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover, .save-btn {
    width: 100%;
    background: #340464;
    color: #fff;
  }
  .MuiInputBase-input {
    background: #f9f9f9 !important;
    border-radius: 4px;
  }
  .form-group {
    .form-control {
      height: 50px;
      padding: 0px 5px;
      background: #f1f1f1;
      border-radius: 4px;
      border: none;
      font-size: 14px;
      margin-top: 4px;
    }
    .form-control:focus-visible {
      outline: none;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

// add market
.add-service-main,
.add-market-main {
  .section-header {
    font-weight: 700;
    font-size: 22px;
    color: #384955;
    display: flex;
  }
  p {
    font-weight: 400;
    font-size: 14px;
  }
  .country-select {
    background: #f9f9f9;
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  .input-field {
    margin-top: 30px;
  }
  .step-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 330px;
    z-index: 99;
    position: relative;
    span {
      background: #340464;
      border-radius: 100px;
      color: #fff;
      width: 29px;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .border-left {
    border: 1px solid #dcdee1;
    position: absolute;
    height: 330px;
    left: 50%;
    z-index: 10;
  }
  .dropzone {
    text-align: center;
    padding: 10px;
    border: 1px dashed #dcdee1;
    background-color: #fafafa;
    color: #bdbdbd;
  }
  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #2596be;
  }
}
.close-icon {
  float: right;
}
