.subscribe-part{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0px;
    .select-part{
        display: flex;
        flex-direction: column;  
        // width: 56%;
        .s-market{
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 5px;
            color: #818689;
        }
        .sel-part{
            margin: "0 10px";
            #demo-simple-select-filled{
                padding-top: 13px;
                padding-bottom: 0px;
                .value-part{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-right: 4px;
                    gap: 6px;
                    justify-content: start;
                    width: 100%;
                    transform: translate(2px, -6px);
                    .inner-part{
                        font-family: 'Roboto', sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 23px;
                        font-size: 20px;
                        color: #384955;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .menuItem{
                border-radius: 4px;
                background-color: #F9F9F9;
            }
            .menuItem::before{
                border-bottom: none;
            }
            .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after{
                border-bottom: none;
            }
        }
    }

    .status-part{
        display: flex;
        align-items: start;
        gap: 5px;
        .market-status{
            display: flex;
            flex-direction: column;
            .m-label{
                font-size: 12px;
                margin-bottom: 5px;
                font-weight: 500;
                color: #384955;
            }
            .score-main{                
                font-weight: 500;
                font-size: 14px;
                margin-left: 5px;
                text-align: center;
                padding: 8px 12px;
            }
            .bage-green{
                background: #1f500870;
                color: #384955;
                padding: 8px 12px;
                margin-left: 5px;
                border-radius: 4px;                
            }
            .bage-light-red{
                background: #d2715d1f;
                color: #384955;
                padding: 8px 12px;
                margin-left: 5px;
                border-radius: 4px;   
            }
            .bage-red {
                background: #f8170054;
                color: #384955;
                padding: 8px 12px;
                margin-left: 5px;
                border-radius: 4px;   
            }
            .bage-dark-red{
                background: #c03c28;
                color: #fff;
                padding: 8px 12px;
                margin-left: 5px;
                border-radius: 4px;   
            }
            .bage{
                background: rgba(131, 210, 93, 0.12);
                color: #384955;
                padding: 8px 12px;
                margin-left: 5px;
                border-radius: 4px;   
            }
        }
    }
}