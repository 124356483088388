.auth-main {
  background: linear-gradient(180deg, #5d7eb5 0%, rgba(93, 126, 181, 0) 122.04%);
}
.login-page {
  height: 100% !important;
  width: 100% !important;
}
.login-welcome {
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  p {
    font-weight: 400;
    font-size: 14px;
  }
}
.header-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  .signin {
    color: #340464;
    text-decoration: underline;
    padding: 0 10px;
  }
  .signup {
    color: #f9f9f9;
  }
}
.register {
  .signup {
    padding: 0 10px;
  }
  .signin {
    padding: 0;
  }
}
.form-group {
  .form-control {
    height: 50px;
    padding: 0px 10px;
    background: #f1f1f1;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    margin-top: 4px;
    width: 100%;
  }
  .form-control:focus-visible {
    outline: none;
  }
}
.login-form {
  background: #ffffff;
  border-radius: 50px 50px 0px 0px;
  padding: 20px;
  margin: 0 -16px -16px;
  .input-login {
    padding: 10px 0;
  }
  .resend-btn,
  .resend-btn.MuiButton-root:hover {
    color: #340464;
    background: #f1f1f1;
    // width: 30%;
    height: 100%;
  }
  .login-btn,
  .login-btn.MuiButton-root:hover {
    background: #340464;
    color: #ffffff;
    padding: 13px;
    margin: 30px 0px;
  }
  .input-field {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    .error-msg {
      color: #e14444;
      font-size: 14px;
      margin-top: 2px;
    }
    label {
      color: #384955;
    }
  }
  .Mui-checked {
    color: #2596be !important;
  }
  .en_eyeicon {
    position: absolute;
    top: 28px;
    right: 5px;
  }
  .ar_eyeicon {
    position: absolute;
    top: 28px;
    left: 5px;
  }
  .eyeIcon{
    position: absolute;
    top: 28px;
    right: 5px;
  }
}
.header-muhnak {
  padding: 20px 0px;
}
.forgotpass {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #340464;
  justify-content: end !important;
  width: fit-content;
  float: right;
  padding: 12px 0px;
}
.not-member-main {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  .not-member {
    color: #889299;
  }
  .regidter-now {
    color: #340464;
    cursor: pointer;
  }
}
.footer-icon {
  padding: 15px;
  color: #889299;
  font-size: 12px;
  line-height: 14px;
  img {
    padding-right: 10px;
  }
}
.divider {
  display: flex;
  align-items: center;
  width: 50%;
  margin: auto;
  color: #dcdee1;
  padding-bottom: 10px;

  .border {
    border-bottom: 1px solid #dcdee1;
    width: 100%;
  }

  .content {
    padding: 0 12px 0 12px;
  }
}

.password-error {
  h4 {
    color: #384955;
  }
  /* Add a green text color and a checkmark when the requirements are right */
  .ar_valid,
  .valid {
    color: green;
  }

  .valid:before {
    position: relative;
    left: -35px;
    content: '✔';
  }

  .ar_valid::before {
    position: relative;
    left: -230px;
    content: '✔';
  }

  /* Add a red text color and an "x" when the requirements are wrong */
  .ar_invalid,
  .invalid {
    color: red;
  }

  .invalid:before {
    position: relative;
    left: -35px;
    content: '✖';
  }
  .ar_invalid::before {
    position: relative;
    left: -230px;
    content: '✖';
  }
}
.pointer {
  cursor: pointer;
}
