.edit-profile {
  margin-top: 20px;
  .default-icon {
    display: flex;
    justify-content: center;
    position: relative;
    .profile-image {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }
    .image-icon {
      position: absolute;
      left: 54%;
      top: 5%;
      width: 42px;
    }
  }
  .form-group {
    .input-field {
      //   margin-top: 15px;
      display: flex;
      flex-direction: column;
      .error-msg {
        color: #e14444;
        font-size: 14px;
        margin-top: 2px;
      }
      label {
        color: #38495599;
      }
      .form-control {
        height: 50px;
        padding: 0px 10px;
        border-radius: 4px;
        border: 1px solid #dcdee1;
        font-size: 14px;
        margin-top: 4px;
      }
      .form-control:focus-visible {
        outline: none;
      }
    }
    .change-title {
      margin: 20px 0px;
      width: 100%;
      span {
        color: #384955;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .save-btn {
      width: 100%;
      background: #340464;
      color: #fff;
      height: 50px;
    }
  }
  .change_pass {
    height: 100%;
    width: 100%;
    opacity: 1;
  }
  .pass-edit {
    opacity: 0;
  }
  .file-upload {
    width: 140px;
    position: absolute;
    left: 42%;
    opacity: 0;
    z-index: 9999;
    top: 12%;
    height: 110px;
  }
  .ar_eyeicon {
    position: absolute;
    top: 54px;
    left: 0px;
  }
  .en_eyeicon {
    position: absolute;
    top: 54px;
    right: 5px;
  }
  .eyeIcon{
    position: absolute;
    top: 28px;
    right: 5px;
  }
}
